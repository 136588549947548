import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getLoggedInUser, isUserAuthenticated } from 'helpers/authUtils'
import * as API from './api/index'
import {userToast} from "helpers/userToast";
import {insertUserApiAccessInfo} from "helpers/userApiAccessInfo";
import {insertSmartFactoryApiResult0214} from "helpers/SmartFactoryApi";
import StockStatus0830 from "../pages/apps/businessManagement/monthlyClosing/report/stock/StockStatus";
import DailyDashBoard0907 from "../pages/apps/businessManagement/dashboard/daily/DailyDashBoard";
import SujuOrderOutsourcedProcess
  from "../pages/apps/salesManagement/order/outsourcedProcess/SujuOrderOutsourcedProcess";

// lazy load all the views

// 0. 로그인
const Register = React.lazy(() => import('../pages/auth/Register'))
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'))
const Confirm = React.lazy(() => import('../pages/auth/Confirm'))
const LoginMaster = React.lazy(() => import('../pages/user/Login'))
const ShowMainPage = React.lazy(() => import('../pages/user/ShowMainPage'))
const LogoutMaster = React.lazy(() => import('../pages/user/Logout'))

// 1. 공통관리

const UserAuthCRUD = React.lazy(() => import('../pages/user/UserAuth/index')) // 1.2.1 권한관리(신규)
const UserAuthCopy = React.lazy(() => import('../pages/user/UserAuthCopy/index')) // 1.2.2 권한관리(복사)
const PartnerCompany2 = React.lazy(() => import('../pages/partnerCompany/index')) // 1.3 업체관리

const PartnerCompany = React.lazy(() => import('../pages/apps/common/partnerCompanyCode/index')) // 1.3 업체관리
const PartnerSalesEaConvert = React.lazy(() => import('../pages/apps/common/partnerSalesEaConvert/SalesEaConvert')) // 1.3 업체관리
const ConnectCompany = React.lazy(() => import('../pages/apps/common/connectCompany/index')) // 연결회사 관리 (* Company 거래처간 연결)
const CompanyCalendar = React.lazy(() => import('../pages/apps/common/companyCalendar')) // 달력관리
const CodeMgtAdmin = React.lazy(() => import('../pages/apps/common/codeMgtAdmin/index')) // 1.4 코드관리(관리자)
const CodeMgtCompany = React.lazy(() => import('../pages/apps/common/codeMgtCompany/index')) // 1.5 코드관리(업체)
const LoginTest = React.lazy(() => import('../pages/user/Login')) // 1.6 로그인
const RegisterUser = React.lazy(() => import('../pages/user/Register')) // 1.7 회원가입

// 2. 경영관리

// 5/30 한울 추가
const Bank = React.lazy(() => import('../pages/apps/businessManagement/managementCode/bank/index'))

const Employee = React.lazy(() => import('../pages/employee/index'))
const EmployeeEnrollment = React.lazy(() => import('../pages/apps/employee/index')) // 근무자 등록
const Position = React.lazy(() => import('../pages/positionInformation/index')) // 직책정보

const LaborExpenseCost = React.lazy(() => import('../pages/apps/businessManagement/cost/LaborExpenses/index')) // 직책정보
const ProductPriceStandard = React.lazy(() => import('../pages/apps/businessManagement/cost/productPriceStandard'))

const Attendance = React.lazy(() => import('../pages/apps/businessManagement/hr/attendance/index')) // 근태관리(52시간)
const RestTime = React.lazy(() => import('../pages/apps/businessManagement/hr/restTime/index')) // 휴게시간설정
//마감관리
const CreateMonthlyClosing = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/index')) // 마감생성
const ClosingList = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/closingList/index')) // 노경비 계정별 등록
const ClosingCost = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/closingCost/index')) // 노경비 등록

const PositionDepartMent = React.lazy(() => import('../pages/apps/businessManagement/position/index'))

const monthlyClosingStatus = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/status/index')) // 마감확정 상태관리
const closingIn = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/closingIn/index')) // 결산등록

const CreateMonthlyClosingErrorLog = React.lazy(() =>  import('../pages/apps/businessManagement/monthlyClosing/errorLog/index'),) // 에러조회

const monthlyReportStock0830 = React.lazy(() =>
    import('../pages/apps/businessManagement/monthlyClosing/report/stock/StockStatus'),
) // 월간 결산 레포트(재고현황)


const closingModalYearlySalesModal = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/report/sales/YearlySalesModal')); // 모달(연간 매출금액 현황)
const closingModalYearlySalesPartnerModal = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/report/salesPartner/YearlySalesPartnerModal')); // 모달(연간 거래처별 매출현황)
const closingModalManufacturingCostReportModal = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/report/manufacturingCost/ManufacturingCostReportModal')); // 모달(월별 제조원가 현황)
const closingModalResultLengthModal0907 = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/report/resultLengh/ResultLengthModal')); // 모달(월별 생산실적 현황)
const closingModalDailyDashBoardModal0907 = React.lazy(() => import('../pages/apps/businessManagement/dashboard/daily/DailyDashBoardModal')); // 모달(일일 경영지표 점검)
const closingModalStockStatusModal1004 = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/report/stock/StockStatusModal')); // 모달(월별 거래처별 재고 현황)

const currentKpi = React.lazy(() => import('../pages/apps/businessManagement/monthlyClosing/currentKpi'))

// 3. 영업관리
//3-1 영업기본정보
const SalesInformation = React.lazy(() => import('../pages/apps/salesManagement/salesInformation/index')) // 영업정보입력
const ProductInformation = React.lazy(() => import('../pages/apps/salesManagement/productInformation/index')) //제품정보입력
const ProductInfoInput = React.lazy(() => import('../pages/apps/salesManagement/basic/product/index')) //3-1-2 제품정보입력
const salesCode = React.lazy(() => import('../pages/code1/productForm/index')) // 영업코드생성
const OrderSalesCode = React.lazy(() => import('../pages/apps/salesManagement/order/sales/index')) // 3.3.1 배송계획(영업코드)
const OrderProductCode = React.lazy(() => import('../pages/apps/salesManagement/order/product/index')) // 3.3.2 배송계획(제품코드)
const ManageBarCode = React.lazy(() => import('../pages/apps/salesManagement/manage/manageBarCode/index'))
const SalesProductPriceManagement = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/price2/index')) // 판매제품 단가관리

//발주관리
const SujuStatus = React.lazy(() => import('../pages/apps/salesManagement/order/sujuStatus/index')) // 수주현황
const outPlan = React.lazy(() => import('../pages/apps/salesManagement/order/outPlan/index')) // 출고계획

//판매관리
const ProductAdjust = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/priceAdjust/productIndex')) // 기타정산

// 0620 소통관리
const communicationManagement = React.lazy(() => import('../pages/apps/salesManagement/basic/communicationManagement'))
const communicationManagementSujuStatus = React.lazy(() => import('../helpers/suju/actionBoard/sujuStatusActionBoard'))
const communicationManagementSujuReport = React.lazy(() => import('../pages/apps/salesManagement/order/sujuReport/index'))
const communicationManagementDailyKpi = React.lazy(() => import('../pages/apps/businessManagement/dashboard/daily/DailyDashBoard'))

const SalesProductOrder = React.lazy(() => import('../pages/apps/salesManagement/order/salesOrder/index')) //영업발주서

const OrderReg = React.lazy(() => import('../pages/apps/salesManagement/order/regOrder/index')) // 수주등록
const OrderReg2 = React.lazy(() => import('../pages/apps/salesManagement/order/regOrder2/index')) // 수주등록

const SujuDelivery = React.lazy(() => import('../pages/apps/salesManagement/order/sujuDelivery/index')) //수주등록(배송)

const FabricOrder = React.lazy(() => import('../pages/apps/salesManagement/order/fabric/index')) //원단발주

const SujuMaterial = React.lazy(() => import('../pages/apps/salesManagement/order/sujuMaterial/index')) //원단발주(new)

const deliveryPlan = React.lazy(() => import('../pages/apps/salesManagement/order/delivery/index')) //출고계획
const deliveryPlan2 = React.lazy(() => import('../pages/apps/salesManagement/order/deliveryPlan/index')) // 출고계획 새로만들기
//판매관리
const manageInput = React.lazy(() => import('../pages/apps/salesManagement/manage/manageInput/index')) // 판매입력
const salesClosing = React.lazy(() => import('../pages/apps/salesManagement/manage/salesClosing/SalesClosing')) // 판매입력(관리자)
const salesStatus = React.lazy(() => import('../pages/apps/salesManagement/manage/salesStatus/index')) // 판매현황
const salesStatus2 = React.lazy(() => import('../pages/apps/salesManagement/manage/salesStatus2')) // 판매현황2
const productReturn = React.lazy(() => import('../pages/apps/salesManagement/manage/productReturn/SalesReturn')) //반품입력
const outInput = React.lazy(() => import('../pages/apps/salesManagement/manage/outInput/index')) //판매입력 (세미)
const outStatus = React.lazy(() => import('../pages/apps/salesManagement/manage/outStatus/index')) //판매현황
const palletPrint = React.lazy(() => import('../pages/apps/salesManagement/manage/palletPrint')) // 라벨출력
const forwardingInput = React.lazy(() => import('../pages/apps/salesManagement/manage/forwardingInput/index')) //출고입력 (물류)

// 4. 구매관리
const MaterialCommonCode = React.lazy(() => import('../pages/apps/purchaseManagement/materialCommonCode/index')) // 자재 공통 코드
const CreateMaterialCode = React.lazy(() => import('../pages/apps/purchaseManagement/createMaterialCode/index')) // 자재코드 생성
const CreateMaterialCost = React.lazy(() => import('../pages/apps/purchaseManagement/createMaterialCost/index')) // 자재코드 생성
const TypeInsertMaterialCost = React.lazy(() => import('../pages/apps/purchaseManagement/typeInsertMaterialCost/index')) // 자재코드 생성
const MaterialInfoInput = React.lazy(() => import('../pages/apps/purchaseManagement/material/info/index')) // 자재정보입력
const MaterialCostUnitInput = React.lazy(() => import('../pages/apps/purchaseManagement/material/cost/index')) // 자재단가입력
const MaterialOrder = React.lazy(() => import('../pages/apps/purchaseManagement/materialOrder/index')) // 자재발주
const OrderManagement = React.lazy(() => import('../pages/apps/purchaseManagement/order/management/ModalMainIndex')) //4-2-4 발주서 관리
const OrderManagementSearch0128 = React.lazy(() => import('../pages/apps/purchaseManagement/order/managementSearch/ModalIndex')) //4-2-4 발주서 관리
const MaterialSujuOrder = React.lazy(() => import('../pages/apps/purchaseManagement/order/materialSujuOrder/index')) //4-2-4 발주서 관리
const MaterialPlanOrder = React.lazy(() => import('../pages/apps/purchaseManagement/order/materialPlanOrder/index')) // 자재발주(생산계획)
const MaterialOrderBySujuList = React.lazy(() =>
  import('../pages/apps/purchaseManagement/order/materialOrderBySujuList/index3'),
) // 자재발주(생산계획)
const PlateManage = React.lazy(() => import('../pages/apps/purchaseManagement/common/plateManage')) // 제판관리  2022-02-22 삭제예정
const RollerOut = React.lazy(() => import('../pages/apps/purchaseManagement/common/rollerOut')) // 롤러출고관리  2022-02-22 삭제예정
const BarCodeInfoProduct = React.lazy(() => import('../pages/apps/purchaseManagement/barCode/infoProduct/index')) //입고관리
const BarCodeHistory = React.lazy(() => import('../pages/apps/purchaseManagement/barCode/barcodeHistory/index'))

//입고관리
const MaterialAdjust = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/priceAdjust/materialIndex')) //기타정산
const SettlementDetail = React.lazy(() => import('../pages/apps/purchaseManagement/barCode/settlementDetail/index')) //거래처별 정산내역
const BuyPrice = React.lazy(() => import('../pages/apps/purchaseManagement/barCode/buyPrice/index')) //구매단가생산성

// 출고관리
const outSellManagement = React.lazy(() => import('../pages/apps/purchaseManagement/out/outSellManagement')) // 매각출고

//생산공통구매관리
const CommonPlate = React.lazy(() => import('../pages/apps/purchaseManagement/common/plate')) // 제판관리
const CommonRoller = React.lazy(() => import('../pages/apps/purchaseManagement/common/roller')) // 롤러출고관리

//재고관리

const StockFilm = React.lazy(() => import('../pages/apps/purchaseManagement/stock/film/index')) // 재고파악(필름)
const StockInk = React.lazy(() => import('../pages/apps/purchaseManagement/stock/ink/index')) // 재고파악(잉크)
const StockCore = React.lazy(() => import('../pages/apps/purchaseManagement/stock/core/index')) // 재고파악(지관)
const StockBox = React.lazy(() => import('../pages/apps/purchaseManagement/stock/box/index')) // 재고파악(박스)
const StockEtc = React.lazy(() => import('../pages/apps/purchaseManagement/stock/etc/index')) // 재고파악(기타)

// 자재관리 > 수불부 생성
const MaterialSubul = React.lazy(() => import('../pages/apps/purchaseManagement/subul/materialSubul')) // 자재수불

const CreateMaterialSubul = React.lazy(() => import('../pages/apps/purchaseManagement/subul/material/index')) // 재고파악(원단)
const CreateEtcMaterialSubul = React.lazy(() => import('../pages/apps/purchaseManagement/subul/etcMaterial/index')) // 재고파악(원단 외)
const InOutSubulMaterial = React.lazy(() => import('../pages/apps/purchaseManagement/subul/inOutSubulMaterial/index')) // 재고파악(자사+외주)

// 5. 생산관리
// const ProductSpec = React.lazy(() => import('../pages/production/basic/productSpec/index')) // 제조사양서NEW
const ProductSpecInfo = React.lazy(() => import('../pages/apps/productionManagement/basic/spec/index')) //제조사양서 관리
const ProductSpecInfoNEW = React.lazy(() => import('../pages/apps/productionManagement/basic/anotherSpec/index')) //제조사양서 관리NEW
const WorkStandardInfo = React.lazy(() => import('../pages/apps/productionManagement/basic/work/index')) //작업표준서 관리
const WorkStandardInfoNEW = React.lazy(() => import('../pages/apps/productionManagement/basic/anotherWork/index')) //작업표준서 관리 NEW
const ChamberInfo = React.lazy(() => import('../pages/apps/productionManagement/others/chamber/index')) //경화실 관리
const ChamberAutoSubul = React.lazy(() => import('../pages/apps/productionManagement/others/chamberAuto/ChamberAutoSubul')) //경화실 관리
const ChamberAutoWorker = React.lazy(() => import('../pages/apps/productionManagement/others/chamberAuto/ChamerAutoWorker')) //경화실 관리

const subulSuju = React.lazy(() => import('../pages/apps/productionManagement/subul/subulSuju/index')) //수불 수주

const subulSemiProduct = React.lazy(() => import('../pages/apps/productionManagement/subul/semiProduct/index')) //반제품 수불 조회

const semiProductGroupByProductProcess = React.lazy(() =>
  import('../pages/apps/productionManagement/subul/semiProductGroupByProductProcess/index'),
) //반제품 수불 조회

const planVsActWeekReport = React.lazy(() => import('../pages/apps/productionManagement/report/planVsAct/index')) //반제품 수불 조회
const lossWeekReport = React.lazy(() => import('../pages/apps/productionManagement/report/lossWeekReport/index')) //반제품 수불 조회
const productInputReport = React.lazy(() => import('../pages/apps/productionManagement/report/productInputReport/index')) //반제품 수불 조회
//지원부서
const RollerInfo = React.lazy(() => import('../pages/apps/supportManagement/machine/rollerInfo/index')) //경화실 관리

//5-2 생산계획
const WorkTimeInput = React.lazy(() => import('../pages/apps/productionManagement/plan/workTimeInput')) // 5-2-1 작업근무시간입력
const newWorkTimeInput = React.lazy(() => import('../pages/apps/productionManagement/plan/newWorkTimeInput/index'))

const PlanWorkTime = React.lazy(() => import('../pages/apps/productionManagement/plan/workTime/index')) // 작업근무시간입력 최종 2022.03.25

const ProcessPlan = React.lazy(() => import('../pages/apps/productionManagement/plan/process/index')) // 5-2-2 생산계획
// con
//st ProgressSearchPlan = React.lazy(() => import('../pages/apps/productionManagement/plan/progressSearch/index')) // 5-2-3 작업진행사항조회(인쇄)
const ProgressLaminating = React.lazy(() => import('../pages/apps/productionManagement/plan/progressLaminating/index')) // 5-2-3 작업진행사항조회(가공)
const ProgressSlitting = React.lazy(() => import('../pages/apps/productionManagement/plan/progressSlitting/index')) // 5-2-3 작업진행사항조회(분단)
// 0613 새로 만듬
const WorkProgress = React.lazy(() => import('../pages/apps/productionManagement/plan/workProgress'))
const CylinderSubul = React.lazy(() => import('../pages/apps/productionManagement/cylinder/location/CylinderLocationByProduct')) // 작업근무시간입력
const PrintInput = React.lazy(() => import('../pages/apps/productionManagement/performance/print/index')) // 5-3-1 생산실적 입력(인쇄)
const LaminatingInput = React.lazy(() => import('../pages/apps/productionManagement/performance/laminating/index')) // 5-3-2 생산실적 입력(가공)
const SlittingInput = React.lazy(() => import('../pages/apps/productionManagement/performance/slitting/index')) // 5-3-3 생산실적 입력(분단)
const PouchInput = React.lazy(() => import('../pages/apps/productionManagement/performance/pouch/index')) // 5-3-4 생산실적 입력(제대)
const PerformanceRecycle = React.lazy(() => import('../pages/apps/productionManagement/performance/recycle')) // 생산실적(재생)
const OperationRate = React.lazy(() => import('../pages/apps/productionManagement/performance/operationRate')) // 가동률 현황
const PlanVsAct = React.lazy(() => import('../pages/apps/productionManagement/performance/planVsAct')) // 생산계획 대 실적
const SujuPlan = React.lazy(() => import('../pages/apps/productionManagement/plan/suju/index')) //생산계획(수주)

const SujuPlan2 = React.lazy(() => import('../pages/apps/productionManagement/plan/sujuPlan/index')) //생산계획(수주)2
const SujuProcessPlan = React.lazy(() => import('../pages/apps/productionManagement/plan/sujuProcessPlan/SujuProcessPlan0406')) //생산계획(수주)2
const ProductPricePouch = React.lazy(() => import('../pages/apps/productionManagement/price/ProductPricePouch')) // 제조원가추정(파우치)



//로스관리
const LossDetail = React.lazy(() => import('../pages/apps/productionManagement/loss/detail/index')) // 로스관리
const LossItem = React.lazy(() => import('../pages/apps/productionManagement/loss/item/index')) // 로스관리

//5-5 재고관리
const StockRealSemi = React.lazy(() => import('../pages/apps/productionManagement/stock/realSemi/index')) // 재고실사(반제품)
const StockRealZ = React.lazy(() => import('../pages/apps/productionManagement/stock/realZ/index')) // 재고실사(완제품)
const ResultSearch = React.lazy(() => import('../pages/apps/productionManagement/stock/resultSearch/index')) // 제품건별실적조회

// 6. 지원관리  --------------------------------------------------------------------------------------
const MachineCRUD = React.lazy(() => import('../pages/apps/supportManagement/machine/index')) // 6-4 설비관리
const RollerInput = React.lazy(() => import('../pages/apps/supportManagement/machine/rollerInput')) // 롤러정보입력

// 7. 외주관리
// 7.1 기초정보
const OutsourcingManagementBasicPrice = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/price')) // 외주단가관리
const OutsourcingManagementBasicPrice2 = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/basic/price2/index'),
) // 외주단가관리

const BasicStructure = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/basicStructure/index')) // 외주기초자료
const BasicStructure2 = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/basic/basicStructure2/BaseStructure'),
) // 외주기초자료
const CalCulatorUnit = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/history/index')) //정산내역조회

const BasicClaim = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/claim')) // 업체별 클레임
const BasicPriceCs = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/priceCs')) // 금액조정

const OutSourcingSearch = React.lazy(() => import('../pages/apps/outsourcingManagement/in/search')) //외주조회

const OutsourcingOutInsert = React.lazy(() => import('../pages/apps/outsourcingManagement/out/insert')) // 외주출고입력
const OutsourcingInInsert = React.lazy(() => import('../pages/apps/outsourcingManagement/in/insert')) // 외주입고입력

const OutsourcingOrderPurchase = React.lazy(() => import('../pages/apps/outsourcingManagement/order/purchase')) //외주발주서(일반)

const OutSourcingOutInsertNew = React.lazy(() => import('../pages/apps/outsourcingManagement/newOut/insert')) // 외주출고입력(BOM 일반)
const OutSourcingInInsertBOM = React.lazy(() => import('../pages/apps/outsourcingManagement/in/insertBOM')) //외주입고(bom 외주업체)
const FromOutsourcingToFactory = React.lazy(() => import('../pages/apps/outsourcingManagement/in/confirmInFromOutsourcing/ConfirmInFromOutsourcing')) //외주입고(from 외주업체-유상,무상)

const OutSourcingInsertInNew = React.lazy(() => import('../pages/apps/outsourcingManagement/newIN/insert')) //외주입고(BOM 일반)
const OutSourcingOutInsertBOM = React.lazy(() => import('../pages/apps/outsourcingManagement/out/insertBOM')) //외주출고(BOM 외주업체)
const OutsourcingOutTotal0302 =  React.lazy(() => import('../pages/apps/outsourcingManagement/out/outsourcingOutTotal/OutsourcingOutTotal0302')) //외주출고

const OutsourcingManagementResultInsert = React.lazy(() => import('../pages/apps/outsourcingManagement/result/index')) // 외주단가관리

const OutSourcingPlan = React.lazy(() => import('../pages/apps/outsourcingManagement/partnerCompany/plan')) //외주거래처생산계획
const OutSourcingPlan2 = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/partnerCompany/outPerformancePlan'),
) //외주거래처생산계획
const monthlyInventory = React.lazy(() => import('../pages/apps/outsourcingManagement/partnerCompany/monthlyInventory')) // 월말재고실사

const OutSourcingSubulMaterial = React.lazy(() => import('../pages/apps/outsourcingManagement/subul/material/index')) //자재수불부
const OutSourcingSubulProductProcess = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/subul/productProcess/index'),
) //제품수불부

const OutSourcingClosingUnit = React.lazy(() => import('../pages/apps/outsourcingManagement/basic/closing/index')) // 마감내역조회
const OutSourcingmonthlyClosing = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/basic/outsourcingMonthlyClosing/index'),
) // 신규마감내역조회

// 7.2 외주정산
const OutsourcingManagementMonthlyClosing = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/basic/monthlyClosing'),
) // 외주정산
/*외주거래처*/
const OutsourcingWorkingProcess = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/partnerCompany/workingProcess'),
) // 진행사항

const DomesticBarcode = React.lazy(() =>
  import('../pages/apps/outsourcingManagement/partnerCompany/domesticBarcode/index'),
) //바코드발행(내수용)
const ExportBarcode = React.lazy(() => import('../pages/apps/outsourcingManagement/partnerCompany/exportBarcode/index')) //바코드발행(수출용)
/**/

//7.품질관리
const ReportStandard = React.lazy(() => import('../pages/apps/qualityManagement/report/standard/index')) // 7-1-1 성적서 (기준입력)
const ReportPerformance = React.lazy(() => import('../pages/apps/qualityManagement/report/performance/index')) // 7-1-2 성적서 (실적)
const ReportRangeStandard = React.lazy(() => import('../pages/apps/qualityManagement/report/rangeStandard'))
const ReturnHistory = React.lazy(() => import('../pages/apps/qualityManagement/return/history')) // 반품내역
const QualityRecheckSchedule = React.lazy(() => import('../pages/apps/qualityManagement/recheckSchedule/QualityRecheckSchedule')) // 반품내역

//지관관리
const CorePrice = React.lazy(() => import('../pages/apps/coreManagement/price/index')) //단가입력
const CoreSort = React.lazy(() => import('../pages/apps/coreManagement/sort/index')) //선별작업
const CoreCalculate = React.lazy(() => import('../pages/apps/coreManagement/calculate/index')) //거래내역정산
const CoreInOutInput = React.lazy(() => import('../pages/apps/coreManagement/inOutInput/index')) //생산입출고입력
const CoreInOutSearch = React.lazy(() => import('../pages/apps/coreManagement/inOutSearch/index')) //생산입출고조회
const CoreSubul = React.lazy(() => import('../pages/apps/coreManagement/subul/index')) // 지관 수불부

// 식당관리
const SelectEatOrNot = React.lazy(() => import('../pages/apps/restaurantManagement/eatOrNot')) // 식사유무
const RestaurantCheck = React.lazy(() => import('../pages/apps/restaurantManagement/restaurantCheck')) // 식사체크
const CommonRestaurant = React.lazy(() => import('../pages/apps/restaurantManagement/admin/commonInfo')) // 식당 공통정보
const RestaurantMenu = React.lazy(() => import('../pages/apps/restaurantManagement/admin/RestaurantMenu')) // 식단조회, 식단등록
const ResultRestaurant = React.lazy(() => import('../pages/apps/restaurantManagement/admin/resultRestaurant')) // 식수점검
const RestaurantReport = React.lazy(() => import('../pages/apps/restaurantManagement/admin/restaurantReport')) // 식수점검

//8. 시스템관리자
const MenuCRUD = React.lazy(() => import('../pages/user/MenuAccess/index')) // 시스템관리>프로그램관리>메뉴관리
const CompanyAuthPage = React.lazy(() => import('../pages/auth/pageAuth/index')) // 시스템관리>권한관리>업체별권한설정
const CompanyCode = React.lazy(() => import('../pages/partnerCompany/index')) // 시스템관리>거래처관리>업체관리
const UserMenuAccessInfo = React.lazy(() => import('../pages/user/UserMenuAccessHistory/index')) // 시스템관리>시스템모니터링>메뉴별 접속 순위
const UserMenuHistorySecondMenu = React.lazy(() => import('../pages/user/UserMenuHistorySecond/Index')) // 시스템관리>시스템모니터링>메뉴별 접속 순위
const UserMenuAccessHistory = React.lazy(() =>
  import('../pages/apps/systemManagement/userMenuAccessStatistics/menuStatistics'),
) // 시스템관리>시스템모니터링>메뉴별 이용내역

const PartnerCompanyLogoImage = React.lazy(() => import('../pages/apps/systemManagement/image/logoIndex')) // 시스템관리>이미지관리>로고

// 99. 코드예시
const ExampleMasterPage = React.lazy(() => import('pages/example/index'))
const ContextExample = React.lazy(() => import('pages/example/context/index'))
const ReactSelectExample = React.lazy(() => import('../pages/example/reactSelect/index'))
const HanulTest = React.lazy(() => import('../pages/example/reactSelect/lnuvy/hanulTest'))
const InputExample = React.lazy(() => import('../pages/example/input/index'))
const LodashExample = React.lazy(() => import('pages/example/lodash/index'))
const ArduinoMain0424 = React.lazy(() => import('pages/arduino/dashBoard/ArduinoMain'))


// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />
      }

      // console.log('path :' + path)
      // API.userMenuAccessInfoInsert(path)

      // console.log('roles :' + roles)

      /* 2021.07.17(토) 코드 리뷰 이후 삭제 예정
                const loggedInUser = getLoggedInUser();
                // check if route is restricted by role
                if (roles && roles.indexOf(loggedInUser.role) === -1) {
                  // role not authorised so redirect to home page
                  return <Redirect to={{ pathname: '/' }} />;
                }
                */

      const PageList = sessionStorage.getItem('pages')
      const PageListString = JSON.stringify(PageList)
      if (PageListString.indexOf(path) > -1) {
        // console.log('권한이 존재하는 프로그램입니다.')
      } else {
        userToast('error','잘못된 접근입니다.')
        return <Redirect to={{ pathname: '/' }} />;
      }

      if (props.history.action === 'PUSH') {
        insertUserApiAccessInfo(path)

        window.location.reload() // CSS 파일이 다른 화면으로 전환되었음에도 남아있는 현상때문에 강제로 페이지 Refresh (2022.03.09) - 임영웅

      } else {
        // console.log('일반화면 전환')
        insertSmartFactoryApiResult0214()
      }

      // authorised so return component
      return <Component {...props} />
    }}
  />
)

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/MainPage" />,
  route: PrivateRoute,
}

const commonAppRoutes = {
  path: '/apps/common',
  name: '공통정보',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/common/auth',
      name: '권한관리',
      component: UserAuthCRUD,
      route: PrivateRoute,
    },
    {
      path: '/example/lodash/index',
      name: '권한복사',
      component: LodashExample,
      route: PrivateRoute,
    },
    {
      path: '/apps/common/partnerCode',
      name: '업체관리',
      component: PartnerCompany,
      route: PrivateRoute,
    },
    {
      path: '/apps/common/partnerSalesEaConvert',
      name: '업체별정산기준',
      component: PartnerSalesEaConvert,
      route: PrivateRoute,
    },
    {
      path: '/apps/common/connectCompany',
      name: '연결회사관리',
      component: ConnectCompany,
      route: PrivateRoute,
    },
    {
      path: '/apps/common/companyCalendar',
      name: '달력관리',
      component: CompanyCalendar,
      route: PrivateRoute,
    },
    {
      path: '/apps/common/commonCode',
      name: '코드관리',
      component: MaterialCommonCode,
      route: PrivateRoute,
    },
    /* 공통코드 변경으로 인한 삭제
        {
          path: '/apps/common/codeMgtAdmin',
          name: '코드관리(관리자)',
          component: CodeMgtAdmin,
          route: PrivateRoute,
        },
        {
          path: '/apps/common/codeMgtCompany',
          name: '코드관리(업체)',
          component: CodeMgtCompany,
          route: PrivateRoute,
        },
      */
    {
      path: '/apps/common/login',
      name: '로그인',
      component: LoginTest,
      route: PrivateRoute,
    },
    {
      path: '/apps/common/register',
      name: '회원가입',
      component: RegisterUser,
      route: PrivateRoute,
    },
  ],
}

const topMenuCommunicationManagement = {
  path: '/apps/communicationManagement',
  name: '소통관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/communicationManagement/issueLog',
      name: '이슈관리',
      component: communicationManagement,
      route: PrivateRoute,
    },
    {
      path: '/apps/communicationManagement/sujuStatus',
      name: '수주진행점검',
      component: communicationManagementSujuStatus,
      route: PrivateRoute,
    },
  ],
}


const businessManagement = {
  path: '/apps/businessManagement',
  name: '경영관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/businessManagement/managementCode',
      name: '코드관리',
      children: [
        {
          path: '/apps/businessManagement/managementCode/bank',
          name: '금융거래처',
          component: Bank,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/businessManagement/hr',
      name: '인사관리',
      children: [
        // {
        //   path: '/apps/businessManagement/hr/Information',
        //   name: '인원정보',
        //   component: Employee,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/employee/enrollment',
          name: '근무자정보',
          component: EmployeeEnrollment,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/hr/attendance',
          name: '근태관리(52시간)',
          component: Attendance,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/hr/restTime',
          name: '휴게시간설정',
          component: RestTime,
          route: PrivateRoute,
        },

        {
          path: '/apps/businessManagement/hr/positionDepartMent',
          name: '부서직책정보',
          component: PositionDepartMent,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/businessManagement/cost',
      name: '원가관리',
      children: [
        {
          path: '/apps/businessManagement/cost/aop',
          name: 'AOP 계획대실적',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/cost/act',
          name: '원가현황',
          component: ShowMainPage,
          route: PrivateRoute,
        },

        {
          path: '/apps/businessManagement/cost/productCostAnalysis',
          name: '제품별원가분석',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/cost/LaborCostExpenses',
          name: '노경비입력',
          component: LaborExpenseCost,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/cost/productPriceStandard',
          name: '경영단가 설정',
          component: ProductPriceStandard,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/businessManagement/monthlyClosing',
      name: '결산관리',
      children: [
        {
          path: '/apps/businessManagement/monthlyClosing/closingList',
          name: '계정코드 등록',
          component: ClosingList,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/monthlyClosing/closingCost',
          name: '노경비 등록',
          component: ClosingCost,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/monthlyClosing/create',
          name: '월말결산',
          component: CreateMonthlyClosingErrorLog,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/monthlyClosing/status',
          name: '마감확정',
          component: monthlyClosingStatus,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/businessManagement/monthlyClosing/closingIn',
        //   name: '결산등록(매입,매출)',
        //   component: closingIn,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/businessManagement/monthlyClosing/errorLog',
        //   name: '오류조회',
        //   component: monthlyReportStock0830,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/businessManagement/monthlyClosing/currentKpi',
        //   name: 'kpi 현황',
        //   component: currentKpi,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/businessManagement/kpiManagement',
      name: '경영정보',
      children: [
        {
          path: '/apps/businessManagement/kpiManagement/annualSalesAmount',
          name: '연간 매출금액 현황',
          component: closingModalYearlySalesModal,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/kpiManagement/annualSalesAmountByCustomer',
          name: '연간 거래처별 매출현황',
          component: closingModalYearlySalesPartnerModal,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/kpiManagement/monthlyManufacturingCost',
          name: '월별 제조원가 현황',
          component: closingModalManufacturingCostReportModal,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/kpiManagement/monthlyProductionPerformance',
          name: '월별 생산실적 현황',
          component: closingModalResultLengthModal0907,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/kpiManagement/dailyKpiManagement',
          name: '일일 경영지표 점검',
          component: closingModalDailyDashBoardModal0907,
          route: PrivateRoute,
        },
        {
          path: '/apps/businessManagement/kpiManagement/monthlyPartnerInventory',
          name: '월별 거래처별 재고현황',
          component: closingModalStockStatusModal1004,
          route: PrivateRoute,
        },
      ],
    },
    // {
    //   path: '/apps/businessManagement/communicationManagement',
    //   name: '소통관리',
    //   children: [
    //     {
    //       path: '/apps/businessManagement/communicationManagement/sujuReport',
    //       name: '수주현황레포트',
    //       component: communicationManagementSujuReport,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: '/apps/businessManagement/communicationManagement/kpi',
    //       name: '일일경영지표점검',
    //       component: communicationManagementDailyKpi,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },

  ],
}

const salesManagement = {
  path: '/apps/salesManagement',
  name: '영업관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/salesManagement/basic',
      name: '제품코드정보',
      children: [
        {
          path: '/apps/salesManagement/basic/salesInformation',
          name: '영업코드',
          component: SalesInformation,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/basic/product',
          name: '생산코드',
          component: ProductInfoInput,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/basic/productionProgress',
          name: '생산진행',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/basic/salesCostEstimate',
          name: '사전원가',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/salesManagement/basic/communicationManagement',
        //   name: '소통관리',
        //   component: communicationManagement,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/salesManagement/order',
      name: '수주관리',
      children: [
        {
          path: '/apps/salesManagement/order/sales',
          name: '배송계획(업체코드)',
          component: OrderSalesCode,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/order/product',
          name: '배송계획(제품코드)',
          component: OrderProductCode,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/salesManagement/order/regOrder',
        //   name: '수주등록',
        //   component: OrderReg,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/salesManagement/order/regOrder2',
          name: '수주등록',
          component: OrderReg2,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/order/regOrderOutsourcedProcess',
          name: '수주등록(반제품)',
          component: SujuOrderOutsourcedProcess,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/order/sujuStatus',
          name: '수주현황',
          component: SujuStatus,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/order/sujuStatusByProductProcess',
          name: '수주현황(공정)',
          component: communicationManagementSujuStatus,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/order/outPlan',
          name: '판매출고계획',
          component: outPlan,
          route: PrivateRoute,
        },

        {
          path: '/apps/salesManagement/order/sujuDelivery',
          name: '수주등록(배송)',
          component: SujuDelivery,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/order/salesOrder',
          name: '외주발주서(영업)',
          component: SalesProductOrder,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/salesManagement/order/fabric',
        //   name: '원단발주(삭제예정)',
        //   component: FabricOrder,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/salesManagement/order/sujuMaterial',
        //   name: '원단발주(수주)',
        //   component: SujuMaterial,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/salesManagement/order/deliveryPlan',
        //   name: '출고계획(_미사용)',
        //   component: deliveryPlan,
        //   route: PrivateRoute,
        // },

        // {     //20220907 사용안함
        //   path: '/apps/salesManagement/order/deliveryPlan2',
        //   name: '출고계획',
        //   component: deliveryPlan2,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/salesManagement/manage',
      name: '판매관리',
      children: [
        {
          path: '/apps/salesManagement/manage/productCost',
          name: '제품단가등록',
          component: SalesProductPriceManagement,
          route: PrivateRoute,
        },

        {
          path: '/apps/salesManagement/manage/productReturn',
          name: '반품등록',
          component: productReturn,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/manage/inspectionReport',
          name: '성적서관리',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/salesManagement/manage/manageBarCode',
        //   name: '판매입력(바코드)',
        //   component: ManageBarCode,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/salesManagement/manage/manageInput',
          name: '판매등록',
          component: manageInput,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/manage/salesClosing',
          name: '판매등록(관리자)',
          component: salesClosing,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/manage/forwardingInput',
          name: '출고등록(물류)',
          component: forwardingInput,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/salesManagement/manage/outInput',
        //   name: '판매입력(세미)',
        //   component: outInput,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/salesManagement/manage/outStatus',
          name: '판매현황',
          component: outStatus,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/salesManagement/manage/salesStatus',
        //   name: '판매입력(_미사용)',
        //   component: salesStatus,
        //   route: PrivateRoute,
        // },
        // 0623 추가
        {
          path: '/apps/salesManagement/manage/salesStatus2',
          name: '판매현황',
          component: salesStatus2,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/manage/productAdjust',
          name: '판매기타정산',
          component: ProductAdjust,
          route: PrivateRoute,
        },
        {
          path: '/apps/salesManagement/manage/labelPrint',
          name: '팔렛트 라벨출력',
          component: palletPrint,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/salesManagement/stock',
      name: '재고관리',
      children: [
        {
          path: '/apps/salesManagement/stock/subul',
          name: '재고수불부',
          component: ShowMainPage,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

//구매관리 추가
const purchaseManagement = {
  path: '/apps/purchaseManagement',
  name: '구매관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/purchaseManagement/material',
      name: '자재기본정보',
      children: [

        {
          path: '/apps/purchaseManagement/material/createMaterialCode',
          name: '자재코드등록',
          component: CreateMaterialCode,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/material/createMaterialCost',
          name: '자재단가관리',
          component: CreateMaterialCost,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/purchaseManagement/material/insertTypeMaterialCost',
        //   name: '단가일괄등록',
        //   component: TypeInsertMaterialCost,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/purchaseManagement/order',
      name: '발주관리',
      children: [
        // {
        //   path: '/apps/purchaseManagement/order/materialSujuOrder',
        //   name: '발주관리(수주_원단)',
        //   component: MaterialSujuOrder,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/purchaseManagement/order/materialOrderBySujuList',
          name: '자재발주등록',
          component: MaterialOrderBySujuList,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/purchaseManagement/order/materialPlanOrder',
        //   name: '발주관리(생산계획)',
        //   component: MaterialPlanOrder,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/purchaseManagement/order/management',
          name: '자재발주서',
          component: OrderManagement,
          route: PrivateRoute,
        },

        // {
        //   path: '/apps/purchaseManagement/order/materialOrder',
        //   name: '자재발주',
        //   component: MaterialOrder,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/purchaseManagement/order/rawMaterialOrder',
          name: '원재료발주',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/order/subMaterialOrder',
          name: '부재료발주',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/order/etcMaterialOrder',
          name: '소모품발주',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/order/orderHistory',
          name: '발주이력관리',
          component: OrderManagement,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/purchaseManagement/barCode',
      name: '입고관리',
      children: [
        {
          path: '/apps/purchaseManagement/barCode/infoProduct',
          name: '입고등록',
          component: BarCodeInfoProduct,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/barCode/barcodeHistory',
          name: '입고현황Ⅰ(기간별)',
          component: BarCodeHistory,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/order/managementSearch0128',
          name: '입고현황Ⅱ(항목별)',
          component: OrderManagementSearch0128,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/barCode/materialAdjust',
          name: '구매기타정산',
          component: MaterialAdjust,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/barCode/settlementDetail',
          name: '거래처별 정산내역',
          component: SettlementDetail,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/barCode/buyPrice',
          name: '구매단가생산성',
          component: BuyPrice,
          route: PrivateRoute,
        },

      ],
    },
    {
      path: '/apps/purchaseManagement/out',
      name: '출고관리',
      children: [
        {
          path: '/apps/purchaseManagement/out/vendor',
          name: '매각출고',
          component: outSellManagement,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/purchaseManagement/out/others',
        //   name: '기타출고',
        //   component: OutSourcingOutInsertBOM,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/purchaseManagement/stock',
      name: '재고관리',
      children: [
        {
          path: '/apps/purchaseManagement/stock/createSubul',
          name: '수불부생성',
          component: StockEtc,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/stock/film',
          name: '재고실사(필름)',
          component: StockFilm,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/stock/ink',
          name: '재고실사(잉크)',
          component: StockInk,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/stock/core',
          name: '재고실사(지관)',
          component: StockCore,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/stock/box',
          name: '재고실사(박스)',
          component: StockBox,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/stock/etc',
          name: '재고실사(기타)',
          component: StockEtc,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/purchaseManagement/subul',
      name: '수불관리',
      children: [
        {
          path: '/apps/purchaseManagement/subul/materialSubul',
          name: '자재 수불부',
          component: MaterialSubul,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/subul/createMaterialSubul',
          name: '수불부(원단)',
          component: CreateMaterialSubul,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/subul/CreateEtcMaterialSubul',
          name: '수불부(기타)',
          component: CreateEtcMaterialSubul,
          route: PrivateRoute,
        },
        {
          path: '/apps/purchaseManagement/subul/inOutSubulMaterial',
          name: '통합수불부',
          component: InOutSubulMaterial,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

const productionManagement = {
  path: '/apps/productionManagement',
  name: '생산관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/productionManagement/basic/spec',
      name: '생산기본정보',
      children: [
        {
          path: '/apps/productionManagement/production/basic/spec/info',
          name: '제조사양서 관리',
          component: ProductSpecInfo,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/production/basic/anotherSpec',
          name: '제조사양서조회',
          component: ProductSpecInfoNEW,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/production/basic/work',
          name: '작업표준서 관리',
          component: WorkStandardInfo,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/production/basic/anotherWork',
          name: '작업표준서 관리(new)',
          component: WorkStandardInfoNEW,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/productionManagement/production/basic/bom',
        //   name: 'BOM관리',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/productionManagement/production/basic/inkBom',
        //   name: '잉크소요량관리',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/productionManagement/plan',
      name: '생산계획',
      children: [
        // 0613 새로 만듬
        // {
        //   path: '/apps/productionManagement/plan/workProcess',
        //   name: '작업진행사항',
        //   component: WorkProgress,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/productionManagement/plan/progressSearch',
        //   name: '작업진행사항조회(인쇄)',
        //   component: ProgressSearchPlan,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/productionManagement/plan/progressLaminating',
        //   name: '작업진행사항조회(가공)',
        //   component: ProgressLaminating,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/productionManagement/plan/progressSlitting',
        //   name: '작업진행사항조회(분단)',
        //   component: ProgressSlitting,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/productionManagement/plan/workTime',
          name: '근무편성',
          component: PlanWorkTime,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/plan/workTimeInput',
          name: '작업근무시간입력',
          component: WorkTimeInput,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/plan/sujuPlan2',
          name: '생산계획(수주)',
          component: SujuPlan2,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/plan/sujuPlan3',
          name: '생산계획(수주공정)',
          component: SujuProcessPlan,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/plan/process',
          name: '생산계획',
          component: ProcessPlan,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/plan/planVsActWeekReport',
          name: '계획vs실적',
          component: planVsActWeekReport,
          route: PrivateRoute,
        },

        // {
        //   path: '/apps/productionManagement/plan/sujuPlan',
        //   name: '생산계획(수주)_미사용',
        //   component: SujuPlan,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/productionManagement/performance',
      name: '생산실적',
      children: [
        {
          path: '/apps/productionManagement/performance/print',
          name: '생산실적(인쇄)',
          component: PrintInput,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/performance/laminating',
          name: '생산실적(가공)',
          component: LaminatingInput,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/performance/slitting',
          name: '생산실적(분단)',
          component: SlittingInput,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/performance/recycle',
          name: '재생실적(자사)',
          component: QualityRecheckSchedule,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/performance/operationRate',
          name: '가동률 현황',
          component: OperationRate,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/performance/planVsAct',
          name: '생산계획 대 실적',
          component: PlanVsAct,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/performance/productInputReport',
          name: '생산실적점검',
          component: productInputReport,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/productionManagement/performance/pouch',
        //   name: '생산실적(제대)',
        //   component: PouchInput,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/productionManagement/performance/recovering',
        //   name: '생산실적(재생)',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/productionManagement/mes',
      name: 'MES',
      children: [
        {
          path: '/apps/productionManagement/mes/mesResult',
          name: 'MES실적',
          component: ShowMainPage,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/productionManagement/stock',
      name: '재고관리',
      children: [
        {
          path: '/apps/productionManagement/stock/semiProduct',
          name: '반제품재고현황',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/productionManagement/stock/realSemi',
        //   name: '재고실사(반제품)',
        //   component: StockRealSemi,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/productionManagement/stock/realZ',
        //   name: '재고실사(완제품)',
        //   component: StockRealZ,
        //   route: PrivateRoute,
        // },

        {
          path: '/apps/productionManagement/stock/resultSearch',
          name: '제품건별실적조회',
          component: ResultSearch,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/productionManagement/loss',
      name: '로스관리',
      children: [
        {
          path: '/apps/productionManagement/loss/detail',
          name: '로스내역',
          component: LossDetail,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/loss/item',
          name: '로스내역(전년대비)',
          component: LossItem,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/loss/lossWeekReport',
          name: '로스내역확인',
          component: lossWeekReport,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/loss/employeeLossResult',
          name: '근무자별로스내역',
          component: ShowMainPage,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/productionManagement/others',
      name: '생산관리(기타)',
      children: [
        {
          path: '/apps/productionManagement/others/cylinder/subul',
          name: '동판위치관리',
          component: CylinderSubul,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/others/chamber',
          name: '경화실관리',
          component: ChamberAutoWorker,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/others/chamberAdmin',
          name: '경화실관리(관리자)',
          component: ChamberAutoSubul,
          route: PrivateRoute,
        },



      ],
    },
    {
      path: '/apps/productionManagement/common',
      name: '생산공통구매관리',
      children: [
        {
          path: '/apps/productionManagement/common/plate',
          name: '제판관리',
          component: CommonPlate,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/common/roller',
          name: '롤러출고관리',
          component: CommonRoller,
          route: PrivateRoute,
        },
      ],
    },

    {
      path: '/apps/productionManagement/subul',
      name: '수불관리',
      children: [
        {
          path: '/apps/productionManagement/subul/subulSuju',
          name: '수불부',
          component: subulSuju,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/subul/semiProduct',
          name: '수불부(Lot)',
          component: subulSemiProduct,
          route: PrivateRoute,
        },
        {
          path: '/apps/productionManagement/subul/semiProductGroupByProductProcess',
          name: '수불부(제품)',
          component: semiProductGroupByProductProcess,
          route: PrivateRoute,
        },
      ],
    },

  ],
}

// 지원부서
const supportManagement = {
  path: '/apps/supportManagement',
  name: '지원부서',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/supportManagement/others',
      name: '기타',
      children: [
        // {
        //   path: '/apps/supportManagement/others/paletteInfo',
        //   name: '팔렛트 기초정보',
        //   component: MachineCRUD,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/supportManagement/others/paletteHistory',
          name: '팔렛트 입출고내역',
          component: ShowMainPage,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/supportManagement/others/paletteRetrieve',
        //   name: '팔렛트 회수',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
        // {
        //   path: '/apps/supportManagement/others/HiredCarInfo',
        //   name: '용차 기초정보',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/supportManagement/others/HiredCarUsage',
          name: '용차 사용내역',
          component: ShowMainPage,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/supportManagement/machine',
      name: '설비관리',
      children: [
        {
          path: '/apps/supportManagement/machine/CRUD',
          name: '설비이력등록',
          component: MachineCRUD,
          route: PrivateRoute,
        },

        {
          path: '/apps/supportManagement/machine/rollerInfo',
          name: '롤러정보입력',
          component: RollerInfo,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/supportManagement/machine/preventInspection',
        //   name: '설비예방점검',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
      ],
    },
  ],
}

// 외주관리
const outsourcingManagement = {
  path: '/apps/outsourcingManagement',
  name: '외주관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/outsourcingManagement/basic',
      name: '기초정보',
      children: [
        {
          path: '/apps/outsourcingManagement/basic/basicStructure',
          name: '외주기초자료',
          component: BasicStructure,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/basic/basicStructure2',
          name: '외주기초정보(new)',
          component: BasicStructure2,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/outsourcingManagement/basic/price2',
        //   name: '외주단가관리',
        //   component: OutsourcingManagementBasicPrice2,
        //   route: PrivateRoute,
        // },


        // {
        //   path: '/apps/outsourcingManagement/basic/monthlyClosing',
        //   name: '외주정산',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/outsourcingManagement/basic/history',
          name: '정산내역조회',
          component: CalCulatorUnit,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/basic/closing',
          name: '마감내역조회',
          component: OutSourcingClosingUnit,
          route: PrivateRoute,
        },

      ],
    },
    // {
    //   path: '/apps/outsourcingManagement/result',
    //   name: '생산실적',
    //   children: [
    //     {
    //       path: '/apps/outsourcingManagement/result/insert',
    //       name: '실적입력',
    //       component: OutsourcingManagementResultInsert,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
    {
      path: '/apps/outsourcingManagement/out',
      name: '출고관리',
      children: [
        // {
        //   path: '/apps/outsourcingManagement/out/insert',
        //   name: '출고입력(바코드)',
        //   component: OutsourcingOutInsert,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/outsourcingManagement/out/insert',
          name: '출고입력(자사)',
          component: OutSourcingOutInsertNew,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/outsourcingManagement/in',
      name: '입고관리',
      children: [
        {
          path: '/apps/outsourcingManagement/in/search',
          name: '외주조회',
          component: OutSourcingSearch,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/outsourcingManagement/in/insert',
        //   name: '입고입력(바코드)',
        //   component: OutsourcingInInsert,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/outsourcingManagement/in/insertBOM',
          name: '입고입력(BOM)',
          component: OutSourcingInInsertBOM,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/in/fromOutsourcingToFactory',
          name: '입고입력(자사)',
          component: FromOutsourcingToFactory,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/in/outsourcingMonthlyClosing',
          name: '마감현황(거래처)',
          component: OutSourcingmonthlyClosing,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/in/claim',
          name: '업체별 클레임',
          component: BasicClaim,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/in/priceCs',
          name: '금액조정',
          component: BasicPriceCs,
          route: PrivateRoute,
        },


      ],
    },
    {
      path: '/apps/outsourcingManagement/order',
      name: '발주관리',
      children: [
        {
          path: '/apps/outsourcingManagement/order/purchase',
          name: '외주발주서',
          component: OutsourcingOrderPurchase,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/outsourcingManagement/partnerCompany',
      name: '외주거래처',
      children: [
        {
          path: '/apps/outsourcingManagement/partnerCompany/in',
          name: '입고입력(BOM)',
          component: OutSourcingInInsertBOM,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/outBOM',
          name: '무상출고(BOM)',
          component: OutSourcingOutInsertBOM,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/salesOut',
          name: '판매출고(BOM)',
          component: OutSourcingOutInsertBOM,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/totalOut',
          name: '판매출고(종합)',
          component: OutsourcingOutTotal0302,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/outsourcingManagement/partnerCompany/workingProcess',
        //   name: '진행사항',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/outsourcingManagement/partnerCompany/domesticBarcode',
          name: '바코드발행(내수용)',
          component: DomesticBarcode,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/exportBarcode',
          name: '바코드발행(수출용)',
          component: ExportBarcode,
          route: PrivateRoute,
        },

        {
          path: '/apps/outsourcingManagement/partnerCompany/plan',
          name: '생산계획',
          component: OutSourcingPlan,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/outPerformancePlan',
          name: '외주실적',
          component: OutSourcingPlan2,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/workerOutPerformancePlan',
          name: '외주실적(생산)',
          component: OutSourcingPlan2,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/returnRework',
          name: '재생실적(외주)',
          component: QualityRecheckSchedule,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/partnerCompany/monthly',
          name: '월말재고실사',
          component: monthlyInventory,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/outsourcingManagement/subul',
      name: '수불부',
      children: [
        {
          path: '/apps/outsourcingManagement/subul/materialSubul',
          name: '자재수불부(외주)',
          component: MaterialSubul,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/subul/subulSuju',
          name: '제품수불부(외주)',
          component: subulSuju,
          route: PrivateRoute,
        },
        {
          path: '/apps/outsourcingManagement/subul/material',
          name: '수불부(원단)',
          component: OutSourcingSubulMaterial,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/outsourcingManagement/subul/etcMaterial',
        //   name: '수불부(기타)',
        //   component: OutSourcingSubulMaterial,
        //   route: PrivateRoute,
        // },
        {
          path: '/apps/outsourcingManagement/subul/productProcess',
          name: '제품수불부',
          component: OutSourcingSubulProductProcess,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

// 품질관리
const qualityManagement = {
  path: '/apps/qualityManagement',
  name: '품질관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/qualityManagement/report',
      name: '성적서관리',
      children: [
        {
          path: '/apps/qualityManagement/report/standard',
          name: '성적서(기준입력)',
          component: ReportStandard,
          route: PrivateRoute,
        },
        {
          path: '/apps/qualityManagement/report/rangeStandard',
          name: '성적서 오차범위',
          component: ReportRangeStandard,
          route: PrivateRoute,
        },
        {
          path: '/apps/qualityManagement/report/performance',
          name: '성적서(실적)',
          component: ReportPerformance,
          route: PrivateRoute,
        },
        {
          path: '/apps/qualityManagement/report/salesCarOutReport',
          name: '판매성적서인쇄',
          component: salesClosing,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/qualityManagement/badQuality',
      name: '부적합',
      children: [
        {
          path: '/apps/qualityManagement/badQuality/ㄱ',
          name: '부적합내역',
          component: ShowMainPage,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/qualityManagement/return',
      name: '반품',
      children: [
        {
          path: '/apps/qualityManagement/return/history',
          name: '반품내역',
          component: ReturnHistory,
          route: PrivateRoute,
        },
        // {
        //   path: '/apps/qualityManagement/return/kpi',
        //   name: '반품률관리',
        //   component: ShowMainPage,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/apps/qualityManagement/qualityRecheck',
      name: '반품 및 부적합',
      children: [
        {
          path: '/apps/qualityManagement/qualityRecheck/processSchedule',
          name: '반품 및 부적합관리',
          component: QualityRecheckSchedule,
          route: PrivateRoute,
        }
      ],
    },
    // {
    //   path: '/apps/outsourcingManagement/in',
    //   name: '입고관리',
    //   children: [
    //     {
    //       path: '/apps/outsourcingManagement/in/insert',
    //       name: '입고입력',
    //       component: ShowMainPage,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
    // {
    //   path: '/apps/outsourcingManagement/partnerCompany',
    //   name: '외주거래처',
    //   children: [
    //     {
    //       path: '/apps/outsourcingManagement/partnerCompany/workingProcess',
    //       name: '진행사항',
    //       component: ShowMainPage,
    //       route: PrivateRoute,
    //     },
    //     {
    //       path: '/apps/outsourcingManagement/partnerCompany/barcode',
    //       name: '바코드관리',
    //       component: ShowMainPage,
    //       route: PrivateRoute,
    //     },
    //   ],
    // },
  ],
}

// 지관관리
const coreManagement = {
  path: '/apps/coreManagement',
  name: '지관관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/coreManagement/price',
      name: '단가입력',
      component: CorePrice,
      route: PrivateRoute,
    },
    {
      path: '/apps/coreManagement/sort',
      name: '선별작업',
      component: CoreSort,
      route: PrivateRoute,
    },
    {
      path: '/apps/coreManagement/calculate',
      name: '거래내역정산',
      component: CoreCalculate,
      route: PrivateRoute,
    },
    {
      path: '/apps/coreManagement/inOutInput',
      name: '생산입출고입력',
      component: CoreInOutInput,
      route: PrivateRoute,
    },
    {
      path: '/apps/coreManagement/inOutSearch',
      name: '생산입출고조회',
      component: CoreInOutSearch,
      route: PrivateRoute,
    },
    {
      path: '/apps/coreManagement/subul',
      name: '지관수불부',
      component: CoreSubul,
      route: PrivateRoute,
    },
  ],
}

// 식당관리
const restaurantManagement = {
  path: '/apps/restaurant',
  name: '식당관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/restaurant/check',
      name: '식사체크',
      component: RestaurantCheck,
      route: PrivateRoute,
    },
    {
      path: '/apps/restaurant/select',
      name: '식사유무',
      component: SelectEatOrNot,
      route: PrivateRoute,
    },
    {
      path: '/apps/restaurant/admin',
      name: '식당관리',
      children: [
        {
          path: '/apps/restaurant/admin/commonInfo',
          name: '기본정보',
          component: CommonRestaurant,
          route: PrivateRoute,
        },
        {
          path: '/apps/restaurant/admin/readMenu',
          name: '식단조회',
          component: RestaurantMenu,
          route: PrivateRoute,
        },
        {
          path: '/apps/restaurant/admin/menu',
          name: '식단등록',
          component: RestaurantMenu,
          route: PrivateRoute,
        },
        {
          path: '/apps/restaurant/admin/resultRestaurant',
          name: '식수점검(식당)',
          component: ResultRestaurant,
          route: PrivateRoute,
        },
        {
          path: '/apps/restaurant/admin/resultAdmin',
          name: '식수점검(관리자)',
          component: ResultRestaurant,
          route: PrivateRoute,
        },
        {
          path: '/apps/restaurant/admin/report',
          name: '결산레포트',
          component: RestaurantReport,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

// 시스템관리
const systemManagement = {
  path: '/apps/systemManagement',
  name: '시스템관리',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/systemManagement/program',
      name: '프로그램관리',
      children: [
        {
          path: '/apps/systemManagement/program/menu',
          name: '메뉴관리',
          component: MenuCRUD,
          route: PrivateRoute,
        },
        {
          path: '/apps/systemManagement/program/userMenuAccess',
          name: '메뉴이용내역',
          component: UserMenuAccessInfo,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/systemManagement/auth',
      name: '권한관리',
      children: [
        {
          path: '/apps/systemManagement/auth/pages',
          name: '업체별권한설정',
          component: CompanyAuthPage,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/systemManagement/company',
      name: '거래처관리',
      children: [
        {
          path: '/apps/systemManagement/company/crud',
          name: '업체관리',
          component: CompanyCode,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/systemManagement/systemMonitoring',
      name: '시스템 모니터링',
      children: [
        {
          path: '/apps/systemManagement/systemMonitoring/menu',
          name: '메뉴별 접속 순위',
          component: UserMenuAccessInfo,
          route: PrivateRoute,
        },
        {
          path: '/apps/systemManagement/systemMonitoring/menuStatistics',
          name: '메뉴별 이용내역 ',
          component: UserMenuAccessHistory,
          route: PrivateRoute,
        },
        {
          path: '/apps/systemManagement/systemMonitoring/userMenuAccess',
          name: '사용자 접속현황',
          component: UserMenuHistorySecondMenu,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/apps/systemManagement/image',
      name: '이미지관리',
      children: [
        {
          path: '/apps/systemManagement/image/logo',
          name: '로고관리',
          component: PartnerCompanyLogoImage,
          route: PrivateRoute,
        },
        {
          path: '/apps/systemManagement/image/logo2',
          name: '로고관리2',
          component: PartnerCompanyLogoImage,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

const examplePages = {
  path: '/apps/example',
  name: '코드예시',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/example/stateManagement',
      name: '코드예시',
      children: [
        {
          path: '/apps/example/stateManagement/ExampleMasterPage',
          name: '코드예시_IndexPage',
          component: ExampleMasterPage,
          route: PrivateRoute,
        },
        {
          path: '/apps/example/stateManagement/context',
          name: '콘텍스트',
          component: ContextExample,
          route: PrivateRoute,
        },
        {
          path: '/apps/example/master',
          name: 'React_Select',
          component: ReactSelectExample,
          route: Route,
        },
        // 0614 한울 추가
        {
          path: '/apps/example/hanul',
          name: 'hanul',
          component: HanulTest,
          route: Route,
        },
        {
          path: '/apps/example/input',
          name: 'input_example',
          component: InputExample,
          route: PrivateRoute,
        },
        {
          path: '/apps/example/ArduinoDashboard',
          name: '아두이노(Dashboard)',  // 개발 TEST
          component: ProductPricePouch,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: LoginMaster,
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: LogoutMaster,
      route: Route,
    },
    {
      path: '/account/register',
      name: 'Register',
      component: Register,
      route: Route,
    },
    {
      path: '/account/confirm',
      name: 'Confirm',
      component: Confirm,
      route: Route,
    },
    {
      path: '/account/forget-password',
      name: 'Forget Password',
      component: ForgetPassword,
      route: Route,
    },
    {
      path: '/MainPage',
      name: 'MainPage',
      component: ShowMainPage,
      route: Route,
    },
  ],
}

const appRoutes = [
  commonAppRoutes, // 1. 공통관리
  businessManagement, // 2. 경영관리
  topMenuCommunicationManagement, // 3. 소통관리
  salesManagement, // 4. 영업관리
  purchaseManagement, // 5. 구매관리
  productionManagement, // 6. 생산관리
  supportManagement, // 7. 지원관리
  outsourcingManagement, // 8. 외주관리
  qualityManagement, // 9. 품질관// 리
  coreManagement, // 지관관리
  restaurantManagement, // 식당관리
  systemManagement, // 10. 권한관리
  examplePages,
]

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = []

  routes = routes || []
  routes.forEach((item) => {
    flatRoutes.push(item)

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// All routes
const allRoutes = [rootRoute, ...appRoutes, authRoutes]

const authProtectedRoutes = [...appRoutes]

const allFlattenRoutes = flattenRoutes(allRoutes)

export { allRoutes, authProtectedRoutes, allFlattenRoutes }
