import dayjs from "dayjs";
import _ from 'lodash'

export const getBaseDate = ({ baseDate = '2023-06-07' }) => {
    const thisYear = Number(dayjs(baseDate).set('date',1).format("YYYY"))
    const lastYear = Number(thisYear) - 1

    const nowStartDate= dayjs(baseDate).set('date',1).format("YYYY-MM-DD")
    const nowEndDate = baseDate
    const preStartDate = dayjs(nowStartDate).set('year',lastYear).format("YYYY-MM-DD")
    const preEndDate = dayjs(nowEndDate).set('year',lastYear).format("YYYY-MM-DD")


    return {nowStartDate,nowEndDate,preStartDate,preEndDate,thisYear,lastYear,baseDate}

}


export const getRatio0911 = ({ top =0, bottom = 0}) => {
    if(top === 0 || bottom === 0) return 0
    const tempValue =  _.floor((top / bottom) ,2) * 100
    return _.floor(tempValue)
}

