import React,{useContext} from 'react';
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import {Button, Input} from "reactstrap";
import _ from 'lodash'
import {getSujuPP241015, updateSujuPP241017} from "../api";
import {nanoid} from "nanoid";
import {getPartnerCompanyList} from "helpers/getUserInfo";
import {validateInsertData} from "../function";
import {userToast} from "helpers/userToast";
import OutsourcedProcessSales241020 from "helpers/outsourcing/outsourcedProcessSales/OutsourcedProcessSales";

const HeaderSujuOP = () => {
    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const inputComponent = ({type="date",columnName="endDate"})=>{

        const handleInputChange = (e)=>{
            const tempObj = {...state.headerOptions,[columnName]:e.target.value}
            dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"headerOptions",value: tempObj})
        }

        return   <Input  bsSize={"sm"} type={"date"} name={columnName} value={state.headerOptions[columnName]} style={{width:"150px"}} onChange={handleInputChange} />
    }

    const checkboxComponent = ({type="checkbox",labelName=""})=>{
        return (
            <div style={{display:"flex",flexDirection:"row",alignContent:"center"}}>
                <span>{labelName}</span>
                <input type="checkbox" checked={true}/>
            </div>
        )
    }

    const RowFlex = (props)=>{
        return(
            <div style={{display: "flex",flexDirection: "row",gap:"0.2rem",padding:"0.2rem",justifyContent:"flex-start",alignContent:"center"}}>
                {props.children}
            </div>
        )
    }

    const handleAddButtonClick = ()=>{
        const addObj =  {...state.initObj,rowId:nanoid()} ?? {}
        let CL = _.cloneDeep(state.filteredTableList) ?? []
        let CL1 = [addObj,...CL]
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"filteredTableList",value: CL1})
    }

    const handleSearchButtonClick = async ()=>{
        const partnerList = await getPartnerCompanyList()
        const {startDate,endDate} = state.headerOptions ?? ""
        const headerOptions = {startDate,endDate}

        const apiResult = await getSujuPP241015({param:"",dataBasket:headerOptions});
        const CL = apiResult.map((v)=>{
            return {...v,rowId:nanoid()}
        })
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"mainTable",value: CL})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"filteredTableList",value: CL})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"partnerList",value: partnerList})
    }

    const handleSaveButtonClick = async ()=>{
        const errorCheck = validateInsertData(state.filteredTableList)
        if(errorCheck > 0) return userToast('','필수 입력자료중 누락된 사항이 있습니다!')

        const apiResult =  await updateSujuPP241017({param:"",dataBasket:state.filteredTableList} );
        console.log("apiResult",apiResult)
        if(apiResult.success){
            userToast('','저장완료')
            handleSearchButtonClick()
        }
    }

    return (
        <RowFlex>
            {inputComponent({type:"date",columnName:"startDate"})}
            {inputComponent({type:"date",columnName:"endDate"})}
            <Button size={"sm"} color={"secondary"} onClick={handleSearchButtonClick}>조회</Button>
            <Button size={"sm"} color={"secondary"} onClick={handleAddButtonClick}>추가</Button>
            <Button size={"sm"} color={"secondary"} onClick={handleSaveButtonClick} style={{marginLeft:"3rem", marginRight:"3rem"}}>저장</Button>
            <OutsourcedProcessSales241020 />
            {/*<RowFlex>*/}
            {/*    {checkboxComponent({type:"checkbox",columnName:"checkbox",labelName:"취소포함"})}*/}
            {/*    {checkboxComponent({type:"checkbox",columnName:"checkbox",labelName:"취소포함"})}*/}
            {/*    {checkboxComponent({type:"checkbox",columnName:"checkbox",labelName:"취소포함"})}*/}
            {/*</RowFlex>*/}
            {/*<span>엑셀기능</span>*/}
        </RowFlex>
    );
};

export default HeaderSujuOP;